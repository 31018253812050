/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// Import the component at the top of the file
import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import "./src/components/layout/layout.css"
import AlertContextProvider from "./src/components/HOC/AlertContext/AlertContext"

export const wrapRootElement = ({ element }) => (
  <AlertContextProvider>{element}</AlertContextProvider>
)
