import { defineStyleConfig } from "@chakra-ui/react"

const Link = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 400,
    fontFamily: "'Oswald', sans-serif"
  },
})

export default Link
