import React, { useCallback, useMemo } from "react"
import client from "../../../util/sanityClient"

const AlertContext = React.createContext({})

function AlertContextProvider({ children }) {
  const [show, setShow] = React.useState(false)
  const [firstAlert, setFirstAlert] = React.useState("")

  const params = useMemo(
    () => ({
      now: new Date().toISOString(),
    }),
    []
  )

  const query = `*[_type == "alert" && startDay < $now && endDay > $now ]{
    title,
    alertType,
    startDay,
    endDay,
    message
  }[0]`

  const getAlerts = useCallback(() => {
    client.fetch(query, params).then(data => {
      setFirstAlert(data)
      if (data) {
        setShow(true)
      }
    })
  }, [query, params])

  React.useEffect(() => {
    getAlerts()
  }, [getAlerts])

  return (
    <AlertContext.Provider
      value={{
        show,
        setShow,
        firstAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default AlertContextProvider

export { AlertContext }
