// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react"
import Button from "../../theme/Button"
import Link from "../../theme/Link"
import Heading from "../../theme/Heading"

const theme = {
  colors: {
    primary: "rebeccapurple",
    laser: {
      50: "#f1fbe3",
      100: "#daf1bf",
      200: "#c3e797",
      300: "#acdd6f",
      400: "#94d448",
      500: "#7bba2f",
      600: "#609124",
      700: "#446818",
      800: "#283e0b",
      900: "#0b1600",
    },
    brand: {
      50: "#ffe7e9",
      100: "#f5bdc0",
      200: "#ec9197",
      300: "#e5666e",
      400: "#de3d45",
      500: "#c4252b",
      600: "#991c22",
      700: "#6e1418",
      800: "#420a0e",
      900: "#1a0104",
    },
    gray: {
      50: "#feeff2",
      100: "#ded7db",
      200: "#c2bec0",
      300: "#a8a4a6",
      400: "#8e8a8c",
      500: "#747073",
      600: "#5b5759",
      700: "#423e41",
      800: "#2a2428",
      900: "#110912",
    },
  },
  components: {
    Button,
    Link,
    Heading
  },
}

export default extendTheme(theme)

// 565254
// 6DD3CE
// F29CA3
// F7CACD
// C8E9A0

// F7CACD
