import { defineStyleConfig } from "@chakra-ui/react"

const Button = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: "bold",
    textTransform: "capitalize",
    borderRadius: "base", // <-- border radius is same for all variants and sizes
  },
  // Two sizes: sm and md
  sizes: {
    sm: {
      fontSize: "sm",
      px: 4, // <-- px is short for paddingLeft and paddingRight
      py: 3, // <-- py is short for paddingTop and paddingBottom
    },
    md: {
      fontSize: "md",
      px: 6, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      border: "2px solid",
      borderColor: "purple.500",
      color: "purple.500",
    },
    pink: {
      _hover: {
        filter: 'brightness(0.8)'
      },
      color: 'pink.500',
      bg: 'gray.50'
    },
    solid: {
      bg: "gray.500",
      color: "white",
    },
    brand: {
      bg: "gray.50",
      color: "gray.700",
      border: "1px solid",
      borderColor: "gray.100",
    },
    ghostPink: {
      borderColor: 'pink.200',
      color: 'pink.200',
      border: "2px solid",
      bg: 'rgba(0,0,0,0)',
      _hover: {
        borderColor: 'whiteAlpha.900',
        color: 'whiteAlpha.900',
      }
    },
    ghostLaser: {
      borderColor: 'laser.400',
      color: 'laser.400',
      border: "2px solid",
      bg: 'rgba(0,0,0,0)',
      _hover: {
        borderColor: 'whiteAlpha.900',
        color: 'whiteAlpha.900',
      }
    }
  },
  // The default size and variant values
  defaultProps: {
    size: "md",
    variant: "outline",
  },
})

export default Button
