import { defineStyleConfig } from "@chakra-ui/react"

const Link = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 500,
    _hover: {
      color: "pink.200",
      textDecorationThickness: "1px",
    },
  },
  variants: {
    text: {
      color: "white",
    },
  },
  defaultProps: {
    variant: "text",
  },
})

export default Link
